import React, { useState } from 'react';
import { UpdateUserDetailsContext, UserDetailsContext } from '../services/context/UserDetailsContext.js';
import PropTypes from 'prop-types';
import useQuery from '../services/hooks/useQuery.js';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The Controller Component
 */
export default function UserDetailsController({ slot }) {
  const queryParams = useQuery();
  const [userDetails, setUserDetails] = useState({
    msisdn: queryParams.get('msisdn')?.replace('-', '') ?? '27609000450',
    msisdnDash: queryParams.get('msisdn') ?? '27-609000450',
    email: null,
    userId: queryParams.get('userId') ?? '216610000002564273795',
    subscriberId: null,
    vsp: null,
  });

  return (
    <UserDetailsContext.Provider value={ userDetails }>
      <UpdateUserDetailsContext.Provider value={ setUserDetails }>
        {slot}
      </UpdateUserDetailsContext.Provider>
    </UserDetailsContext.Provider>
  );
}

UserDetailsController.propTypes = { slot: PropTypes.element };
